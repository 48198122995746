
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import {
    Col,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import axios from "../../../api/axios";
import CheckoutForm from './CheckoutForm';
import { VehicleTypes } from '../../../store/Utility/Status';

export const PaymentStep = forwardRef((props, ref) => {
    const checkoutRef = useRef();
    const [stripePromise, setStripePromise] = useState(loadStripe(process.env.REACT_APP_STRIPE_PK));
    const [intent, setIntent] = useState(props.intent ? props.intent : {});

    const createPaymentIntent = async () => {
        await axios.get(`v1/payment/payment-intent/${props.id}`).then(res => {
            setIntent(res.data);
            props.updateFields({ intent: res.data });
        });
    }

    useEffect(() => {
        if (!intent.clientSecret) {
            createPaymentIntent();
        }
    }, [])

    useImperativeHandle(ref, () => ({
        async handleSubmit(e) {
            if (checkoutRef.current) {
                checkoutRef.current.handleSubmit(e);
            }
        }
    }));

    const options = {
        // passing the client secret obtained in step 3
        clientSecret: intent.clientSecret,
        // Fully customizable with appearance API.
        appearance: {/*...*/
            //      theme: 'flat',
            //   variables: { colorPrimaryText: '#262626' }
        },
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
        }
    };
    return (
        <>
            <Row>
                <span class="text"><b>Order Details: </b>{props.vehicleType == VehicleTypes.Car ? 'Car Inspection' :
                    props.vehicleType == VehicleTypes.Commercial ? 'Commercial Inspection' :
                        props.vehicleType == VehicleTypes.Motorcycle ? 'Motorcycle Inspection' :
                            props.vehicleType == VehicleTypes.RV ? 'RV Inspection' : null
                }
                    {props.isCarfaxReportRequested ? ' & CARFAX Report' : null}
                </span>

            </Row>
            <Row>
                <Col md={12}>
                    <span class="text"><b>Total: </b>$ {intent.totalAmount?.toFixed(2)}</span>

                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={12}>

                    {intent.clientSecret && stripePromise && (
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm clientSecret={intent.clientSecret} ref={checkoutRef} />
                        </Elements>)}
                </Col>
            </Row>
        </>
    )
});

// ;  ({ uniqueKey, updateFields, id }) {
