import React, { useEffect, useState } from "react";
import {
    Col,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";

import axios from "../../../api/axios";
import { VehicleTypes } from "../../../store/Utility/Status";
import { categoryInitialData } from "../../../store/Utility/Utility";
import AutoCompleteInput from "../../../Componet/Autocomplete/AutoCompleteInput";
import { YearsArray } from "../../../store/Utility/Data";

export function VehicleStep({ categoryId, vehicleType, makeId, make, modelId, model, year, licensePlate, VIN, updateFields }) {
    const [makes, setMake] = useState();
    const [categories, setCategory] = useState(categoryInitialData);
    const [makeInput, setMakeInput] = useState(make);
    const [modelInput, setModelInput] = useState(model);
    const [models, setModel] = useState();


    const onMakeClick = (clickedItem) => {
        setMakeInput(clickedItem.name);
        setModelInput("");
        getModelByMakeId(clickedItem.id);
        updateFields({ makeId: clickedItem.id, make: clickedItem.name });
        updateFields({ modelId: "", model: "" });
    }

    const onMakeChange = (event) => {
        setMakeInput(event.target.value);
    }

    const onModelChange = (event) => {
        setModelInput(event.target.value);
    }

    const onModelClick = (clickedItem) => {
        updateFields({ modelId: clickedItem.id, model: clickedItem.name });
        setModelInput(clickedItem.name);

    }

    const handleMakeChange = (event) => {
        getModelByMakeId(event.target.value);
        updateFields({ makeId: event.target.value, make: event.target[event.target.selectedIndex].text });
    }

    useEffect(() => {
        const getMakes = async () => {
            const response = await axios.get(`v1/make`).then(res => {
                setMake(res.data.data);
            });
        }
        getMakes();
        if (makeId != null && makeId != "") {
            getModelByMakeId(makeId);
        }
    }, []);

    const getModelByMakeId = async (makeId) => {
        const response = await axios.get(`v1/model/${makeId}`).then(res => {
            setModel(res.data.data);
        });
    }

    return (
        <>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <AutoCompleteInput placeholder="Make" value={makeInput} options={makes} onSuggestionClick={onMakeClick} handleChange={onMakeChange} />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <AutoCompleteInput placeholder="Model" value={modelInput} options={models} onSuggestionClick={onModelClick} handleChange={onModelChange} />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Input
                            type="select"
                            id="year"
                            name="year"
                            value={year}
                            className="form-control"
                            onChange={e => updateFields({ year: e.target.value })}
                        >
                             <option value="">Year</option>
                            {YearsArray.map(t=><option value={t}>{t}</option>)}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            id="licensePlate"
                            type="text"
                            name="licensePlate"
                            className="form-control"
                            placeholder="License Plate (optional)"
                            value={licensePlate}
                            onChange={e => updateFields({ licensePlate: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            id="VIN"
                            type="text"
                            name="VIN"
                            className="form-control"
                            placeholder="VIN (optional)"
                            value={VIN}
                            onChange={e => updateFields({ VIN: e.target.value })}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}