import React, { useState } from "react";
import PhoneMaskInput from "../../../Componet/PhoneMaskInput";
import { Col, FormGroup, Input, Row } from "reactstrap";
import LocationAutocomplete from '../../../Componet/Autocomplete/LocationAutocomplete';
import Options from "../../../Componet/Option/Options";
import { States } from "../../../store/Utility/Data";

export function SellerStep({ sellerContactName, sellerAddress, sellerCity, sellerState, sellerZipCode, sellerPhone, updateFields }) {
    const handleCityChange = (event) => {
        updateFields({ sellerCity: event.target.value });
    }

    const handleStateChange = (event) => {
        updateFields({ sellerState: event.target.value });
    }

    const onSellerPlaceChanged=(place)=>{
        if(!place) return;
        const addressComponents = place.address_components;

        var result = {
            sellerCity: sellerCity,
            sellerState: sellerState,
            sellerZipCode: sellerZipCode,
            sellerAddress: sellerAddress
        };

        const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
        if (state != null) {
            result.sellerState = state.short_name;
        }
        const city = addressComponents.find(component => component.types.includes('locality'));
        if (city != null) {
            result.sellerCity = city.short_name;
        }
        const postalCode = addressComponents.find(component => component.types.includes('postal_code'));

        if (place.formatted_address) {
            result.sellerAddress = place.formatted_address;
        }

        if (postalCode) {
            result.sellerZipCode = postalCode.short_name;
        }

        if (place.geometry.location) {
            result.sellerLatitude = place.geometry.location.lat();
            result.sellerLongitude = place.geometry.location.lng();
        }

        if (Object.keys(result).length > 0) {
           updateFields({...result});
        }
    }

    return (
        <>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <Input
                            id="sellerContactName"
                            type="text"
                            name="sellerContactName"
                            className="form-control"
                            placeholder="Contact Name"
                            value={sellerContactName}
                            onChange={e => updateFields({ sellerContactName: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <LocationAutocomplete onPlaceChanged={onSellerPlaceChanged} placeholder="Address" defaultValue={sellerAddress}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>

                        <Input
                            type="test"
                            id="sellerCity"
                            name="sellerCity"
                            placeholder="City"
                            value={sellerCity}
                            className="form-control custom-form-control"
                            disabled                          
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            type="select"
                            id="sellerState"
                            name="sellerState"
                            value={sellerState}
                            className="form-control custom-form-control"
                            disabled                         
                        >
                           <Options data={States}/>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            id="sellerZipCode"
                            type="text"
                            name="sellerZipCode"
                            className="form-control custom-form-control"
                            placeholder="Zip Code"
                            disabled
                            value={sellerZipCode}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <PhoneMaskInput
                            name="sellerPhone"
                            placeholder="Phone"
                            className="form-control"
                            value={sellerPhone}
                            onChange={e => updateFields({ sellerPhone: e.target.value })}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}