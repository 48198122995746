import React, {useState} from "react";
import {
    Col,
    FormGroup,
    Input,
    Row
} from "reactstrap";


export function InspectorNoteStep({ requesterNote, isTermAndConditionAccepted, updateFields }) {
    const [isTermsAndConditionChecked, setIsTermsAndConditionChecked] = useState(isTermAndConditionAccepted);

   
    const checkHandler = (event) => {
        setIsTermsAndConditionChecked(!isTermsAndConditionChecked);
        updateFields({ isTermAndConditionAccepted: !isTermsAndConditionChecked });
    }
    return (
        <>
            <Row form>
                <Col md={12}>
                    <FormGroup>
                        <Input
                            id="requesterNote"
                            type="textarea"
                            name="requesterNote"
                            className="form-control"
                            placeholder="Please enter if you have any note to inspector (optional)"
                            value={requesterNote}
                            onChange={e => updateFields({ requesterNote: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <div className="form-group mb-0 mt-5">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="termsAndContidition" checked={isTermsAndConditionChecked} onChange={checkHandler} />
                                <label className="form-check-label" for="termsAndContidition" >I accept terms and Conditions</label>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}
