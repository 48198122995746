import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
import PhoneMaskInput from "../../../Componet/PhoneMaskInput";
import LocationAutocomplete from '../../../Componet/Autocomplete/LocationAutocomplete';
import Options from "../../../Componet/Option/Options";
import { States } from "../../../store/Utility/Data";


export function RequesterStep({ requesterFirstName, requesterLastName, requesterEmail, requesterAddress, requesterCity, requesterState, requesterZipCode, requesterPhone, updateFields }) {
    const handleStateChange = (event) => {
        updateFields({ requesterState: event.target.value });
    }

    const onRequesterPlaceChanged = (place) => {
        if (!place) return;
        const addressComponents = place.address_components;

        var result = {
            requesterCity: requesterCity,
            requesterState: requesterState,
            requesterZipCode: requesterZipCode,
            requesterAddress: requesterAddress
        };

        const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
        if (state != null) {
            result.requesterState = state.short_name;
        }
        const city = addressComponents.find(component => component.types.includes('locality'));
        if (city != null) {
            result.requesterCity = city.short_name;
        }
        const postalCode = addressComponents.find(component => component.types.includes('postal_code'));

        if (place.formatted_address) {
            result.requesterAddress = place.formatted_address;
        }

        if (postalCode) {
            result.requesterZipCode = postalCode.short_name;
        }

        if (Object.keys(result).length > 0) {
            updateFields({ ...result });
        }
    }


    return (
        <>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            id="requesterFirstName"
                            type="text"
                            name="requesterFirstName"
                            className="form-control"
                            placeholder="First Name"
                            value={requesterFirstName}
                            onChange={e => updateFields({ requesterFirstName: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            id="requesterLastName"
                            type="text"
                            name="requesterLastName"
                            className="form-control"
                            placeholder="Last Name"
                            value={requesterLastName}
                            onChange={e => updateFields({ requesterLastName: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Input
                            id="requesterEmail"
                            type="text"
                            name="requesterEmail"
                            className="form-control"
                            placeholder="Email"
                            value={requesterEmail}
                            onChange={e => updateFields({ requesterEmail: e.target.value })}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <LocationAutocomplete onPlaceChanged={onRequesterPlaceChanged} placeholder="Address" defaultValue={requesterAddress} />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>

                        <Input
                            type="test"
                            id="requesterCity"
                            name="requesterCity"
                            placeholder="City"
                            value={requesterCity}
                            className="form-control custom-form-control"
                            onChange={e => updateFields({ requesterCity: e.target.value })}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            type="select"
                            id="requesterState"
                            name="requesterState"
                            value={requesterState}
                            className="form-control custom-form-control"
                            onChange={handleStateChange}
                            disabled
                        >
                            <Options data={States} />
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Input
                            id="requesterZipCode"
                            type="text"
                            name="requesterZipCode"
                            className="form-control custom-form-control"
                            placeholder="Zip Code"
                            value={requesterZipCode}
                            onChange={e => updateFields({ requesterZipCode: e.target.value })}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <PhoneMaskInput
                            name="requesterPhone"
                            placeholder="Phone"
                            className="form-control"
                            value={requesterPhone}
                            onChange={e => updateFields({ requesterPhone: e.target.value })}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}