import React, { useState } from "react";
import {
    Col,
    FormGroup,
    Input,
    Row
} from "reactstrap";

import truck from '../../../assets/images/icon/truck.png';
import motorbike from '../../../assets/images/icon/motorbike.png';
import van from '../../../assets/images/icon/van.png';
import car from '../../../assets/images/icon/car.png';

export function VehicleTypeStep({ vehicleType, isCarfaxReportRequested, updateFields }) {
    const [selectedVehicle, setSelectedVehicle] = useState(vehicleType)
    const [isCarfaxChecked, setCarfaxCheck] = useState(isCarfaxReportRequested);

    const onClickHandler = (value) => {
        setSelectedVehicle(value);
        updateFields({ vehicleType: value });
    }

    const checkHandler = (event) => {
        setCarfaxCheck(!isCarfaxChecked);
        updateFields({ isCarfaxReportRequested: !isCarfaxChecked });
    }

    const highlightedStyle = {
        backgroundColor: "#d0faec"
    }

    const cardClass = 'card';
    const borderlessCardClass = "card border-0";

    const dividerClass = "d-flex justify-content-center border-bottom border-light pb-3 mb-3";
    const higlightedDividerClass = "d-flex justify-content-center border-bottom border-white pb-3 mb-3"

    const textPrimaryClass = "h5 text-uppercase text-primary";
    const textDarkClass = "h5 text-uppercase text-dark";

    const borderStye = "1px solid #d2d2d2";
    const borderHoverStyle = "1px solid #ffffff"

    return (
        <>
            <Row form>
                <Col md={12}>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3 col-md-6 mb-5 mb-lg-0" style={{ cursor: "pointer" }} onClick={() => onClickHandler('1')}>
                            {/* <!-- Card --> */}
                            <div className={selectedVehicle == "1" ? borderlessCardClass : cardClass} style={selectedVehicle == "1" ? highlightedStyle : null}>
                                {/* <!-- Body --> */}
                                <div className="card-body py-3 px-3 text-center">
                                    {/* <!-- Badge --> */}
                                    <div className="mb-3"> <span className={selectedVehicle == "1" ? textDarkClass : textPrimaryClass}>Car</span>
                                    </div>
                                    {/* <!-- Price --> */}
                                    <div className={selectedVehicle == "1" ? higlightedDividerClass : dividerClass}>
                                        <div className="p-2 d-inline-block rounded" style={{ border: selectedVehicle == "1" ? borderHoverStyle : borderStye, backgroundColor: "#d0faec" }}>
                                            <div className="f-icon-m text-white">
                                                <img src={car} width={60} />
                                                {/* <i className="flaticon-lightbulb"></i> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Features --> */}
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2">$139.99</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6" style={{ cursor: "pointer" }} onClick={() => onClickHandler('2')}>
                            {/* <!-- Card --> */}
                            <div className={selectedVehicle == "2" ? "card border-0" : "card"} style={selectedVehicle == "2" ? highlightedStyle : null}>
                                {/* <!-- Body --> */}
                                <div className="card-body py-3 px-3 text-center">
                                    {/* <!-- Badge --> */}
                                    <div className="mb-3"> <span className={selectedVehicle == "2" ? textDarkClass : textPrimaryClass}>Motorcycle</span>
                                    </div>
                                    {/* <!-- Price --> */}
                                    <div className={selectedVehicle == "2" ? higlightedDividerClass : dividerClass}>
                                        <div className="p-2 d-inline-block rounded" style={{ border: selectedVehicle == "2" ? borderHoverStyle : borderStye, backgroundColor: "#d0faec" }}>
                                            <div className="f-icon-m text-white">
                                                <img src={motorbike} width={60} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Features --> */}
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2">$139.99</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6 mb-5 mb-lg-0" style={{ cursor: "pointer" }} onClick={() => onClickHandler('3')}>
                            {/* <!-- Card --> */}
                            <div className={selectedVehicle == "3" ? "card border-0" : "card"} style={selectedVehicle == "3" ? highlightedStyle : null}>
                                {/* <!-- Body --> */}
                                <div className="card-body py-3 px-3 text-center">
                                    {/* <!-- Badge --> */}
                                    <div className="mb-3"> <span className={selectedVehicle == "3" ? textDarkClass : textPrimaryClass}>Commercial</span>
                                    </div>
                                    {/* <!-- Price --> */}
                                    <div className={selectedVehicle == "3" ? higlightedDividerClass : dividerClass}>
                                        <div className="p-2 d-inline-block rounded" style={{ border: selectedVehicle == "3" ? borderHoverStyle : borderStye, backgroundColor: "#d0faec" }}>
                                            <div className="f-icon-m text-white">
                                                <img src={truck} width={60} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Features --> */}
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2">$189.99</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 col-md-6" style={{ cursor: "pointer" }} onClick={() => onClickHandler('4')}>
                            {/* <!-- Card --> */}
                            <div className={selectedVehicle == "4" ? "card border-0" : "card"} style={selectedVehicle == "4" ? highlightedStyle : null}>
                                {/* <!-- Body --> */}
                                <div className="card-body py-3 px-3 text-center">
                                    {/* <!-- Badge --> */}
                                    <div className="mb-3"> <span className={selectedVehicle == "4" ? textDarkClass : textPrimaryClass}>RV</span>
                                    </div>
                                    {/* <!-- Price --> */}
                                    <div className={selectedVehicle == "4" ? higlightedDividerClass : dividerClass}>
                                        <div className="p-2 d-inline-block rounded" style={{ border: selectedVehicle == "4" ? borderHoverStyle : borderStye, backgroundColor: "#d0faec" }}>
                                            <div className="f-icon-m text-white">
                                                <img src={van} width={60} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Features --> */}
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-2">$249.99</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
                <Col md={12}>
                    <FormGroup>
                        <div className="form-group mb-0 mt-5">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="customCheck1" checked={isCarfaxReportRequested} onChange={checkHandler} />
                                <label className="form-check-label" for="customCheck1" >I would like to get CARFAX report with my inspection (+ $39.99)</label>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            </Row >
        </>
    )
}

